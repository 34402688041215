// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Link } from 'gatsby'
import TextLoop from 'react-text-loop'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'

import StoriesListing from '../components/StoriesListing'

import seoHelper from '../methods/seoHelper'

import stories from '../data/stories.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Stories',
  nakedPageSlug: 'stories',
  pageAbstract:
    'Since its founding in 2004, Cambridge International School for Girls has consistently educated hundreds of girl students, empowering them to evolve into women of substance. Be they doctors, engineers, teachers, nurses, homemakers, scientists or corporate chiefs, the CIS Girls are sure to make their mark. Here are some stories from our community that prove that.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
  },

  '& h2': {
    '& div': {
      color: '#004195',
    },
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = () => {
  return (
    <StandardPage className={pageStyle} seoData={seoData}>
      <h1 className="mask-h2" style={{ marginBottom: 2 }}>
        Cambridge Community Stories
      </h1>
      <h2 className="mask-h4">
        Stories of&nbsp;
        <TextLoop>
          <span>
            <i>Empowerment</i>
          </span>
          <span>
            <i>Equality</i>
          </span>
          <span>
            <i>Opportunity</i>
          </span>
          <span>
            <i>Achievement</i>
          </span>
        </TextLoop>
      </h2>
      <p>
        Since its founding, Cambridge Innovative School has
        consistently educated hundreds of girl students, empowering them to
        evolve into women of substance. Be they doctors, engineers, teachers,
        nurses, homemakers, scientists or corporate chiefs, the CIS students are
        sure to make their mark. Here are some stories from our community that
        prove that.
      </p>
      <StoriesListing data={stories} />
    </StandardPage>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
