// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import moment from 'moment'

import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import filter from 'lodash/filter'
import uniq from 'lodash/uniq'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { Link } from 'gatsby'
import StackGrid from 'react-stack-grid'
import { Header, Article } from '@bodhi-project/semantic-webflow'

//
import Image from '@bodhi-project/components/lib/Image'

import Tag from 'antd/lib/tag'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/tag/style/css'

import Card from 'antd/lib/card'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/card/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
const { CheckableTag } = Tag

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Desktop
const blockStyle = css({
  '& div.card': {
    background: '#FFFFFF',
    border: '2px solid transparent',
    transition: 'all 200ms ease-in',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,

    '&:hover': {
      border: '2px solid #004394',
    },

    '& a': {
      display: 'block',
      border: 'unset !important',

      '&:hover': {
        border: 'unset !important',
      },

      '& .ant-card-body': {
        padding: '6px 9px',
      },

      '& header': {
        position: 'relative',

        '& .date': {
          background: '#FFFFFF',
          position: 'absolute',
          top: -38,
          left: -9,
          padding: '6px 9px',
        },
      },
    },
  },
})
const blockStyleClass = blockStyle.toString()

/** Cover */
const Cover = ({ image, pageTitle }) => (
  <Image
    src={image}
    rawWidth={1440}
    rawHeight={900}
    style={{
      height: 'auto',
      width: '100%',
      border: 0,
      background: 'transparent',
    }}
    alt={pageTitle}
  />
)

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Block */
class Block extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.state = {
      filter: 'all',
    }

    this.applyFilter = this.applyFilter.bind(this)
  }

  /** applyFilter */
  applyFilter(f) {
    this.setState({ filter: f })
  }

  /** standard renderer */
  render() {
    let showFilter = true
    let itemWidth = '49%'
    const { data, isMobile } = this.props
    ;({ showFilter, itemWidth } = this.props)
    const { filter: filterByCategory } = this.state
    const uniqueSortedCategories = uniq(map(data, 'category')).sort((a, b) => {
      if (a < b) return -1
      if (a > b) return 1
      return 0
    })
    let filteredData = data
    if (filterByCategory !== 'all') {
      filteredData = filter(data, ['category', filterByCategory])
    }
    showFilter = isUndefined(showFilter) ? true : showFilter
    itemWidth = isUndefined(itemWidth) ? '49%' : itemWidth

    return (
      <div className={`mask-p ${blockStyleClass}`}>
        {/** {showFilter === true && (
          <Fragment>
            {filterByCategory === 'all' ? (
              <CheckableTag
                checked
                onClick={() => this.applyFilter('all')}
                style={{ marginBottom: 10 }}
              >
                All Posts
              </CheckableTag>
            ) : (
              <Tag
                onClick={() => this.applyFilter('all')}
                style={{ marginBottom: 10 }}
              >
                All Posts
              </Tag>
            )}
            {map(uniqueSortedCategories, category => (
              <Fragment key={category}>
                {filterByCategory === category ? (
                  <CheckableTag
                    checked
                    onClick={() => this.applyFilter(category)}
                    style={{ marginBottom: 10 }}
                  >
                    {category}
                  </CheckableTag>
                ) : (
                  <Tag
                    onClick={() => this.applyFilter(category)}
                    style={{ marginBottom: 10 }}
                  >
                    {category}
                  </Tag>
                )}
              </Fragment>
            ))}
          </Fragment>
        )} */}

        <StackGrid
          columnWidth={isMobile ? '100%' : itemWidth}
          duration={360}
          gutterWidth={20}
          gutterHeight={42}
          monitorImagesLoaded={true}
        >
          {map(filteredData, (card, index) => {
            const {
              pageTitle,
              nakedPageSlug,
              pageAbstract,
              cover,
              publishedTimestamp,
              
            } = card

            return (
              <div className="card" key={nakedPageSlug}>
                <Link to={`/${nakedPageSlug}`}>
                  <Card
                    cover={<Cover image={cover} pageTitle={pageTitle} />}
                    bordered={false}
                  >
                    <Article>
                      <Header>
                        <h1 className="mask-h5">{pageTitle}</h1>
                        <p style={{ marginBottom: 0 }}>
                          <span style={{ color: '#004195' }}>
                            
                            
                            {moment
                              .unix(publishedTimestamp)
                              .format('ddd, MMMM D, YYYY')}
                          </span>
                          :&nbsp;
                          {pageAbstract}
                        </p>
                      </Header>
                    </Article>
                  </Card>
                </Link>
              </div>
            )
          })}
        </StackGrid>
      </div>
    )
  }
}

Block.propTypes = {
  data: PropTypes.array,
}

Block.defaultProps = {}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Block
